import React, { useEffect, useState } from "react"

import { Link as GatsbyLink } from "gatsby"
import { useTranslation } from "react-i18next"
import { withPrefix } from "gatsby"

export default function LanguageSwitcher({ classNames, location }) {
  const { i18n } = useTranslation()

  const pathname = location.pathname ? location.pathname : ""

  const [switcherText, setSwitcherText] = useState("English")
  const [langCode, setLangCode] = useState()
  const [switcherUrl, setSwitcherUrl] = useState("/")

  useEffect(() => {
    if (i18n.language === "en") {
      setSwitcherText("Español")
      setLangCode("es")

      if (pathname === "/help/") {
        setSwitcherUrl(`/ayuda/`)
      } else {
        setSwitcherUrl(`${pathname.replace("/en/", "/")}`)
      }
    } else {
      setSwitcherText("English")
      setLangCode("en")

      if (pathname === "/ayuda/") {
        setSwitcherUrl(`/help/`)
      } else if (
        pathname === "/app/" ||
        pathname === "/elregalaton/" ||
        pathname.includes("/documents/promotions/rules/")
      ) {
        setSwitcherUrl("/en/")
      } else {
        setSwitcherUrl(`${langCode}${pathname}`)
      }
    }
  }, [i18n, langCode, pathname])

  const switchLanguage = () => {
    i18n.changeLanguage(langCode)
  }

  return (
    <GatsbyLink
      className={classNames}
      to={withPrefix(switcherUrl)}
      id="menu-link-language"
      onClick={switchLanguage}
    >
      {switcherText}
    </GatsbyLink>
  )
}
