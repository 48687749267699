import React from "react"
import { Link as GatsbyLink } from "gatsby"

import i18n from "../../i18n/config"

export default function Link({ children, to, classNames, id }) {
  let path
  if (i18n.language === "en") {
    path = `/${i18n.language}${to}`
  } else {
    path = to
  }
  return (
    <GatsbyLink id={id} className={classNames} to={path}>
      {children}
    </GatsbyLink>
  )
}
